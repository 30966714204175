import React from 'react';
import styled from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link';

import Pagination from '@material-ui/lab/Pagination';

import BlogsCard from '../views/shared/BlogsCard';

import Layout from '../components/Layout';
import TopHeader from '../views/shared/TopHeaderV2';

import HeaderSeo from '../components/HeaderSeo';
import MetaImg from '../assets/img/meta/gogetters.png';

import colors from '../assets/colors';

const BlogPostBannerDesktop = '../assets/img/blogs/BlogPostBannerDesktop.jpg';
const BlogPostBannerMobile = '../assets/img/blogs/BlogPostBannerMobile.jpg';

const Container = styled.div`
  margin: auto;
  max-width: 1155px;
  padding-top: 7rem;
  padding-bottom: 35px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 1200px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
const CardWrapper = styled.div`
  margin-right: 2rem;
  margin-bottom: 1rem;
  @media (max-width: 450px) {
    margin-right: 0;
  }
`;
const StyledPagination = styled(Pagination)`
  && {
    button.MuiPaginationItem-textPrimary.Mui-selected {
      color: ${colors.white};
    }
  }
`;
const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const AllBlogs = props => {
  const { data, location, pageContext } = props;
  const { currentPage, numPages } = pageContext;
  const FIRST_PAGE = 1;

  const handleChangePage = (_, page) => {
    if (page === FIRST_PAGE) {
      navigate('/blog');
    } else {
      navigate(`/blog/${page}`);
    }
  };

  return (
    <Layout>
      <HeaderSeo
        title="GoGet | Blog - Our stories, updates, and exclusive promos"
        description="Learn how GoGet is shaping the future of work for job seekers and helping businesses stay efficient. Hear our thoughts on popular topics and breaking myths!"
        image={MetaImg}
        keywords=""
        pathname={location?.pathname}
      />
      <TopHeader
        backgroundImg={
          <StaticImage
            src={BlogPostBannerDesktop}
            loading="eager"
            style={{ height: '100%' }}
            quality={100}
            objectPosition="left top"
            placeholder="blurred"
            alt="GoGet Community"
          />
        }
        mobileBackgroundImg={
          <StaticImage
            src={BlogPostBannerMobile}
            loading="eager"
            style={{ width: '100%' }}
            objectFit="contain"
            placeholder="blurred"
            alt="GoGet Community"
          />
        }
        h4="Blog"
        secondary
        title="What's on GoGet"
        description={
          <>
            Our stories, latest updates, and exclusive promos.
            <br />
            Find anything you want to know about us.
          </>
        }
        backgroundMobileHeight="47rem"
      />
      <Container>
        {data.allDatoCmsBlog?.edges?.map(({ node }) => {
          if (!node?.originalId) {
            return <h1>No blogs Found</h1>;
          }
          return (
            <CardWrapper>
              <BlogsCard
                key={node?.originalId}
                id={node?.originalId}
                image={node?.topbanner}
                title={node?.title}
                url={node?.slug}
                category={node?.category}
                publishedDate={node?.date}
                description={node?.shortDescription}
              />
            </CardWrapper>
          );
        })}
      </Container>
      <PaginationWrapper>
        <StyledPagination
          count={numPages}
          page={currentPage}
          color="primary"
          onChange={handleChangePage}
        />
      </PaginationWrapper>
    </Layout>
  );
};

export default AllBlogs;

export const blogListQuery = graphql`
  query PaginatedBlogs($skip: Int!, $limit: Int!) {
    allDatoCmsBlog(
      sort: { order: DESC, fields: date }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          originalId
          title
          slug
          shortDescription
          category
          date(formatString: "DD MMM yyyy")
          topbanner {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
        }
      }
    }
  }
`;
