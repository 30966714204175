import React from 'react';
import styled from 'styled-components/macro';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';

import Link from 'gatsby-link';

import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';

import colors from '../../../assets/colors';

import ActivityCard from '../ActivityCard';
import fontSize from '../../../assets/fontSize';

const EmptyBlog = '../../../assets/img/blogs/emptyBlog.png';

const ImageWrapper = styled.div`
  width: 284px;
  height: 172px;
`;
const Title = styled.h4`
  font-weight: 700;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 242px;
  text-overflow: ellipsis;
`;
const Description = styled.p`
  font-weight: 400;
  line-height: 19px;
  font-size: ${fontSize.large};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const Body = styled.div`
  flex: 1;
  margin: 21px 21px 0px 21px;
`;
const StyledButton = styled(Button)`
  && {
    color: ${colors.primary};
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  margin-right: 21px;
  margin-left: 13px;
  @media (max-width: 750px) {
    padding-left: 0;
  }
`;
const CardContainer = styled.div`
  height: 143px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;
const Info = styled.p`
  font-size: ${fontSize.large};
  font-weight: 600;
  margin-left: 21px;
  margin-right: 21px;
`;
const LinkTo = styled(Link)`
  text-decoration: none;
  color: ${colors.black};
`;

const BasicCard = props => {
  const { image, description, title, url, category, publishedDate } = props;
  return (
    <ActivityCard
      maxWidthMediaQuery="824px"
      customStyle={{
        boxShadow: '0px 4px 6px rgba(206, 206, 206, 0.5)',
        borderRadius: 20,
        height: 378,
        width: 284,
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}
    >
      <ImageWrapper>
        {image ? (
          <GatsbyImage
            image={getImage(image)}
            style={{
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px'
            }}
            alt={title}
          />
        ) : (
          <StaticImage
            src={EmptyBlog}
            placeholder="blurred"
            style={{
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px'
            }}
            alt="GoGet Blog"
          />
        )}
      </ImageWrapper>
      <CardContainer>
        <Body>
          <LinkTo to={`/${url}`}>
            <Title title={title}>{title}</Title>
          </LinkTo>
          <Description title={description}>{description}</Description>
        </Body>
        <Info>
          {category} {category && '/'} {publishedDate}
        </Info>
        <StyledLink to={`/${url}`}>
          <StyledButton endIcon={<ArrowForwardIcon />}>READ MORE</StyledButton>
        </StyledLink>
      </CardContainer>
    </ActivityCard>
  );
};

export default BasicCard;
